<template>
    
  <form role="form" @submit="onFormSubmit" name="invoice-form">
    <Select
      id="invoiceCompany"
      label="Company"
      placeholder="Company"
      :selectOptions="{
        options: listCompanies,
        getOptionLabel : listCompaniesOptionLabel
      }"
      v-bind:value.sync="form.company" 
      :labelAsColumn="true"
      :required="true"
    />
    <Select
      id="invoiceCustomer"
      label="Customer"
      placeholder="Customer"
      :selectOptions="{
        options: listCustomers,
        getOptionLabel : listCustomersOptionLabel
      }"
      v-bind:value.sync="form.customer" 
      :labelAsColumn="true"
      :required="true"
    />
    <TextField 
      ref="invoiceTitle"
      v-bind:value.sync="form.title" 
      id="updateName" 
      label="Title" 
      placeholder="Title" 
      :labelAsColumn="true"
      :required="true"
    />
    <div class="hr-line-dashed"></div>
    <div class="row">
      <div class="col-lg-12">
        <h3>Items</h3>
      </div>
    </div>
    <div v-for="(item, index) in form.items" :key="index" class="form-group row">
        <div class="col-lg-2">
          <input :id="'invoiceItemQuantity_'+index" type="text" placeholder="Quantity" class="form-control" v-model="form.items[index].quantity">
        </div>
        <div class="col-lg-6">
          <input :id="'invoiceItemDescription_'+index" type="text" placeholder="Description" class="form-control" v-model="form.items[index].description">
          <input :id="'invoiceItemDetails_'+index" type="text" placeholder="Details" class="form-control" v-model="form.items[index].details">
        </div>
        <div class="col-lg-2">
          <input :id="'invoiceItemAmountWithoutTax_'+index" type="text" placeholder="Amount" class="form-control" v-model="form.items[index].amountWithoutTax">
        </div>
        <div class="col-lg-2">
          <button class="btn btn-primary" type="button" @click="removeItem($event, index)">Remove</button>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-lg-2">
          <input id="invoiceAddItemQuantity" type="text" placeholder="Quantity" class="form-control" v-model="addForm.quantity">
        </div>
        <div class="col-lg-6">
          <input id="invoiceAddItemDescription" type="text" placeholder="Description" class="form-control" v-model="addForm.description">
          <input id="invoiceAddItemDetails" type="text" placeholder="Details" class="form-control" v-model="addForm.details">
        </div>
        <div class="col-lg-2">
          <input id="invoiceAddItemAmountWithoutTax" type="text" placeholder="Amount" class="form-control" v-model="addForm.amountWithoutTax">
        </div>
        <div class="col-lg-2">
          <button class="btn btn-primary" type="button" @click="addItem($event)">Add</button>
        </div>
    </div>

    <div class="hr-line-dashed"></div>
    <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{buttonLabel}}</button>
  </form>

            
</template>

<script lang="ts">
import { toRefs, defineComponent, PropType, computed, onMounted } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { useListCompanies } from '@root/src/client/composables/invoice/useListCompanies';
import { useListCustomers } from '@root/src/client/composables/invoice/useListCustomers';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Select from '@fwk-client/components/panels/input/Select.vue';

export default defineComponent({
    props: {
        invoice: Object as PropType<any>
    },
    components: {
        TextField, Select
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();
        const { listCompanies, isListCompaniesLoading, listCompaniesOptionLabel } = useListCompanies(props, context);
        const { listCustomers, isListCustomersLoading, listCustomersOptionLabel } = useListCustomers(props, context);

        const { invoice } = toRefs(props);

        const emptyForm:any = {
          company : null,
          customer : null,
          title : '',
          items : []
        }

        var form:any = invoice.value ? {
          company : invoice.value.company,
          customer : invoice.value.customer,
          title : invoice.value.title,
          items : invoice.value.items
        } : {...emptyForm};

        const addForm:any = {
          quantity: '',
          description: '',
          details: '',
          amountWithoutTax: ''
        }

        var laddaSubmit:Ladda.LaddaButton|null = null;

        const buttonLabel = computed(() => {
          if(invoice.value && invoice.value._id) { return "UPDATE" }
          else { return "GENERATE" }
        })

        onMounted(() => {
          var updateDetailsButton:HTMLButtonElement|null = document.querySelector( 'form[name=invoice-form] button.ladda-button' );
          laddaSubmit = Ladda.create(updateDetailsButton!);
        })

        const addItem = (evt:Event) => {
          evt.preventDefault();

          var item = {
            "quantity" : addForm.quantity,
            "description" : addForm.description,
            "details" : addForm.details,
            "amountWithoutTax" : addForm.amountWithoutTax
          }

          form.items.push(item);
          
          addForm.quantity = '';
          addForm.description = '';
          addForm.details = '';
          addForm.amountWithoutTax = '';
        }

        const removeItem = (evt:Event, index:number) => {
          evt.preventDefault();
          
          form.items.splice(index,1);
        }

        var formLoading:boolean = false;
        const onFormSubmit = (evt:Event) => {
          evt.preventDefault();

          var options:api.ApiVueOptions =  {
            app: app,
            axiosOptions: {
              // responseType: 'blob'
            }
          }

          var input = {
            "invoiceID" : invoice.value && invoice.value._id ? invoice.value._id : undefined,
            "companyID" : form.company._id,
            "customerID" : form.customer._id,
            "customerType" : form.customer.type,
            "title" : form.title,
            "items" : form.items
          }

          var path = '/api/invoices/create';
          if(invoice.value && invoice.value._id) {
            path = '/api/invoices/update';
          }

          laddaSubmit!.start();
          formLoading = true;

          return api.postAPI(path, input, options).then((response:any) => {
            laddaSubmit!.stop();
            formLoading = false;

            if(response.created) {  
              context.emit('invoice-created', response.invoice);

              // We reset the field
              form = {...emptyForm};
            }
            if(response.updated) {  
              context.emit('invoice-updated', response.invoice);
            }
          })
        }

        const panelLoading = computed(() => {
            return formLoading || isListCustomersLoading.value || isListCompaniesLoading.value;
        })

        return {
          panelLoading,
          listCompanies,
          listCompaniesOptionLabel,
          listCustomers,
          listCustomersOptionLabel,
          onFormSubmit,
          form,
          addForm,
          addItem,
          removeItem,
          buttonLabel
        }
  }
})
</script>