<template>
  <section id="content">
    <PageTitle title="Invoices"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">
      <ListInvoices></ListInvoices>
    </div>

  </section>
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import * as api from '@fwk-client/utils/api';
import { messagesTypes, MessageTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import ListInvoices from '../../panels/igot-web/ListInvoices.vue';
import * as Ladda from 'ladda';

@Component({
  components: { 
    PageTitle,
    ListInvoices
  }
})
export default class Invoices extends mixins(GenericPage) {
  
}
</script>