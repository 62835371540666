import { Ref, ref } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp } from '@fwk-client/utils/vue-3-migration';

interface ListCompaniesInput {
}

export function useListCompanies(props:ListCompaniesInput, {emit}:any) { 
  const app = getApp();

  var isListLoading:Ref<boolean> = ref(false);
  var list:Ref<any[]> = ref([]);

  const updateList = () => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app
    }
    isListLoading.value = true;
    api.getAPI('/api/invoices/companies', options).then((response:any) => {
      if(response.companies) {  
        list.value = response.companies;
      }
      else {
        list.value = [];
      }
      isListLoading.value = false;
    });
  }
  updateList();

  const listOptionLabel = (option:any) => {
    return option.name;
  }

  return {
    listCompaniesOptionLabel:listOptionLabel,
    isListCompaniesLoading:isListLoading,
    listCompanies:list
  }
  
}