<template>
    <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-title">
              <h2>List invoices</h2>
            </div>
            <div :class="'ibox-content p-md' + (listInvoicesLoading ? ' sk-loading' : '')">

              <div v-if="listInvoicesLoading" class="sk-spinner sk-spinner-double-bounce">
                  <div class="sk-double-bounce1"></div>
                  <div class="sk-double-bounce2"></div>
              </div>

              <b-table 
                  outlined striped
                  :items="invoicesProvider"
                  :fields="invoiceFields"
                  ref="listInvoices">
                <template v-slot:cell(select)="row">
                  <p-check :id="'invoiceSelect_'+row.item._id" class="p-default p-curve p-primary" name="listInvoicesSelected" color="success" :value="row.item._id" v-model="listInvoicesSelected"></p-check>
                </template>
                <template v-slot:cell(options)="row">
                  <a href="javascript:void(0)" @click="showUpdateInvoiceModal(row.item)">Update</a>
                  /
                  <a :href="getDownloadURL(row.item)" target="_blank"><i class="fa fa-cloud-download"></i></a>
                  /
                  <a href="javascript:void(0)" @click="confirmRemoveInvoice(row.item)">Delete</a>
                  /
                  <a href="javascript:void(0)" @click="showDuplicateInvoiceModal(row.item)">Duplicate</a>
                </template>
              </b-table>

              <div v-if="listInvoicesSelected.length > 0">
                Several invoices selected.
              </div>
              
              <button class="btn btn-primary" @click="showCreateInvoiceModal()">Create invoice</button>

              <b-modal size="lg" ref="updateInvoiceModal" :title="'Invoice details ('+ invoiceToUpdate.number +')'" hide-footer lazy>
                <InvoiceForm :invoice="invoiceToUpdate" v-on:invoice-updated="onInvoiceUpdated"></InvoiceForm>
              </b-modal>

              <b-modal size="lg" ref="createInvoiceModal" title="Create new invoice" hide-footer lazy>
                <InvoiceForm :invoice="invoiceToDuplicate" v-on:invoice-created="onInvoiceCreated"></InvoiceForm>
              </b-modal>

              <b-modal ref="removeInvoiceModal" 
                  hide-header
                  @ok="removeInvoice">
                Do you want to remove invoice: {{ invoiceToRemove.number }} ?
              </b-modal>

            </div>
          </div>
        </div>
    </div>
</template>

<style scoped>
  td div.pretty {
    margin:0;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';
import PageTitle from '../../panels/PageTitle.vue';
import InvoiceForm from './invoices/InvoiceForm.vue';

@Component({
  components: { 
    PageTitle,
    InvoiceForm
  }
})
export default class ListInvoices extends Vue {

  listInvoicesLoading = false;

  readonly emptyInvoice = {
    "number" : ""
  };

  invoiceToDuplicate:any = null;
  invoiceToUpdate:any = {...this.emptyInvoice};
  invoiceToRemove: any = {...this.emptyInvoice};

  listInvoicesSelected = [];

  invoiceFields = [
      {
        key: "select",
        label: ""
      },
      {
        key: "formattedCreationDateTime",
        label: "Date"
      },
      {
        key: "number"
      },
      {
        key: "company",
        formatter: (value:any, key:any, item:any) => {
          return item.company.name;
        }
      },
      {
        key: "customer",
        formatter: (value:any, key:any, item:any) => {
          if(item.customer.displayName) {
            return item.customer.displayName;
          }
          else if(item.customer.name) {
            return item.customer.name;
          }
          return "";
        }
      },
      {
        key: "formattedTotalAmountWithoutTax",
        label: "Total Amount HT"
      },
      {
        key: "options"
      }
    ];

  invoicesProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    return api.getAPI('/api/invoices/list', options).then((response:any) => {
      if(response.invoices) {  
        return response.invoices;
      }
      else {
        return [];
      }
    })
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  getDownloadURL(invoice:any) {
    return '/'+ this.currentLanguageCode +'/api/invoices/'+invoice._id+'/download';
  }

  onInvoiceCreated() {
    // @ts-ignore
    this.$refs.createInvoiceModal.hide()
    // @ts-ignore
    this.$refs.listInvoices.refresh()
  }

  onInvoiceUpdated(invoice:any) {
    // We update the user
    this.invoiceToUpdate = invoice;
    // @ts-ignore
    this.$refs.listInvoices.refresh()
  }

  onInvoiceRemoved() {
    // @ts-ignore
    this.$refs.listInvoices.refresh()
  }

  confirmRemoveInvoice(invoice:any) {
    this.invoiceToRemove = invoice;
    // @ts-ignore
    this.$refs.removeInvoiceModal.show()
  }

  removeInvoice() {

    var input = {
      "invoiceID" : this.invoiceToRemove._id
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listInvoicesLoading = true;
    
    api.postAPI('/api/invoices/remove', input, options).then((response:any) => {
      if(response.removed) {
        // We update the list of users
        this.onInvoiceRemoved();
      }
      // We reset the user to remove
      this.invoiceToRemove = {...this.emptyInvoice};

      this.listInvoicesLoading = false;
    });
  }

  showUpdateInvoiceModal(invoice:any) {
    this.invoiceToUpdate = invoice;
    // @ts-ignore
    this.$refs.updateInvoiceModal.show()
  }

  showCreateInvoiceModal() {
    // We ensure we do not duplicate existing invoice.
    this.invoiceToDuplicate = null;
    // @ts-ignore
    this.$refs.createInvoiceModal.show()
  }

  showDuplicateInvoiceModal(invoice:any) {
    // We copy invoice to duplicate and remove the id to not update it.
    var invoiceToDuplicate = JSON.parse(JSON.stringify(invoice));
    delete invoiceToDuplicate._id;
    this.invoiceToDuplicate = invoiceToDuplicate;
    // @ts-ignore
    this.$refs.createInvoiceModal.show()
  }
  
}
</script>